import { Component, ElementRef, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as maplibregl from 'maplibre-gl';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SvgIconsService } from '../shared/services/svg-icons.service';
import { MapService } from '../shared/services/map.service';
import { AuthService } from './services/auth.service';
import { MapControlsService } from '../shared/services/map-controls.service';
import { LayersManagerService } from '../shared/services/layers-manager.service';
import siteSettings from '../shared/config/site-settings';
import { MapInitDrawGeomService } from '../shared/services/map-init-draw-geom.service';
import { LayersApiService } from '../shared/services/layers-api.service';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements OnInit, OnDestroy {
  title = 'shym-public-web';
  leftSidenavOpened = true;
  width = 352;
  @ViewChild('drawer', { static: false }) drawer: ElementRef;
  map: maplibregl.Map;
  marker;
  private destroy$ = new Subject();
  styles;
  defaultStyle;
  idMaps = false;
  toolsShow = false;
  layerName;
  typeLayer;
  dbTable;
  attrPopup;
  visiblePopup = 'true';
  popupContent;
  layer3D = false;
  layerFeature = false;
  object3d;
  INTERSECTED;


  constructor(
    private svgIconsService: SvgIconsService,
    private mapService: MapService,
    private authService: AuthService,
    private router: Router,
    private mapControlSvc: MapControlsService,
    private layersManagerService: LayersManagerService,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private mapInitDrawSvc: MapInitDrawGeomService,
    private layersApiService: LayersApiService,

  ) {

    // this.styles = styles;
    // this.defaultStyle = defaultStyle;
    // this.onDocumentClick = this.onDocumentClick.bind(this);
    this.layerName = sessionStorage.getItem('layerName');

  }

  toggleLeftSide(): void {

    this.leftSidenavOpened = !this.leftSidenavOpened;

    setTimeout(() => {

      if (this.width === 352) {
        this.width = 0;
      } else {
        this.width = 352;
      }

      // this.map.invalidateSize();
    }, this.leftSidenavOpened ? 150 : 100);

  }

  popup(): void {
    (document.querySelector('#features') as HTMLElement).style.display =
      'none';
    this.marker.remove();
  }

  ngOnInit(): void {
    setTimeout(() => {
      const map: maplibregl.Map = this.mapService.initMap('mapid');
      this.map = map;

      // this.mapControlSvc.initCoordinates(map);
      // this.mapControlSvc.initLocateControl(this.map);
      // this.mapControlSvc.initPrint(this.map);
      // this.mapControlSvc.initRuler(this.map);
      // this.mapControlSvc.initMeasureControl(this.map);
      // this.mapControlSvc.browserPrint(map);
      // this.mapControlSvc.initHead(this.map);
      // this.mapService.getMap().addLayer(this.mapService.editableLayers);
      // this.mapInitDrawSvc.initNewTools(this.map); // new

      // this.map.on('dblclick', (e: any) => {
      // this.layersManagerService.showGetFeatureInfo(this.map, e.latlng);

      // if (this.marker) {
      //   this.map.removeLayer(this.marker);
      // }

      // if (siteSettings.isCoordinate) {
      //   this.marker = L.marker([e.latlng.lat, e.latlng.lng]);
      //   this.marker.addTo(map).bindPopup('<div style="padding:10px;">' + e.latlng.lat + ', ' + e.latlng.lng + '</div>').openPopup();
      //   // marker.addTo(map);
      //   siteSettings.isCoordinate = false;
      // }
      // });

      // if (sessionStorage.getItem('layerName')) {
      //   this.layerName = sessionStorage.getItem('layerName');
      // }
      // if (sessionStorage.getItem('typeLayer')) {
      //   this.typeLayer = sessionStorage.getItem('typeLayer');
      // }

      // this.map.on("click", (e) => {
      //   alert('111111')
      //   this.getIntersectingObjects();
      // });
  

      this.map.on("click", (e) => {

        console.log('click e res: >>> _ ', e);
        if (this.marker != null) {
          this.marker.remove();
        }

        this.marker = new maplibregl.Marker({
          color: "#18a0fb",
          draggable: true
        }).setLngLat(e.lngLat)
          .addTo(map);

        if (sessionStorage.getItem('layerName')) {
          this.layerName = sessionStorage.getItem('layerName');
        }
        if (sessionStorage.getItem('typeLayer')) {
          this.typeLayer = sessionStorage.getItem('typeLayer');
        }
        if (sessionStorage.getItem('dbTable')) {
          this.dbTable = sessionStorage.getItem('dbTable');
        }

        (document.querySelector('#features') as HTMLElement).style.display = 'block';
        this.layer3D = false;
        this.layerFeature = false;
        var features = map.queryRenderedFeatures(e.point);
        if (features.length <= 0) {
          console.log('features', features);
          // map.on('click', (e: maplibregl.MapMouseEvent) => {
            // const coordinates = e.lngLat;
            // const pos = map.project(coordinates);
            // this.layersManagerService.handleClickOnModel(pos.x, pos.y); 
          // });
          ////////////////////////////////////////////////////////////////
          this.layer3D = true;
          // this.layersManagerService.getIntersectingObjects();
          setTimeout(() => {
            // if (this.typeLayer === '3D') {
            // this.layersApiService.getAttrLayers3D(e.lngLat.wrap().lat, e.lngLat.wrap().lng, this.dbTable)
            //   .subscribe(
            //     (data) => {
            //       this.attrPopup = data
            //     }
            //   );
            sessionStorage.setItem('visiblePopup', this.visiblePopup)
            document.getElementById('features1').innerHTML = JSON.stringify(
              this.object3d,
              null,
              2
            )
          }, 1500);

        } else 
        if (features.length > 0) {
          console.log('features', features);
          this.layerFeature = true;
          setTimeout(() => {
            // console.log('features', features);
            // Limit the number of properties we're displaying for
            // legibility and performance
            var displayProperties = [
              // 'type',
              'properties',
              // 'id',
              // 'layer',
              // 'source',
              // 'sourceLayer',
              // 'state'
            ];
            var displayFeatures = features.map(function (feat) {
              var displayFeat = feat;
              displayProperties.forEach(function (prop) {
                displayFeat[prop] = feat[prop];
              });
              return displayFeat.properties;
            });
            this.popupContent = displayFeatures;



            document.getElementById('features0').innerHTML = JSON.stringify(
              displayFeatures[0],
              null,
              2
            );
          }, 500);

        }







        // });

      });

      // map.on("dblclick", (e) => {
      //   console.log('e db res>>>>>>', e);
      //   (document.querySelector('#features') as HTMLElement).style.display = 'block';
      //   this.layer3D = false;
      //   if (this.typeLayer === '3D') {
      //     this.layer3D = true;
      //     this.layersApiService.getAttrLayers3D(e.lngLat.wrap().lat, e.lngLat.wrap().lng, this.dbTable)
      //       .subscribe(
      //         (data) => {
      //           this.attrPopup = data
      //           console.log('result attr >>>', data);
      //         }
      //       );
      //     sessionStorage.setItem('visiblePopup', this.visiblePopup)
      //     console.log('this.attrPopup', this.attrPopup);
      //     console.log('eeeeeee', document.getElementById('features1').innerHTML);
      //     if (this.attrPopup !== undefined && document.getElementById('features1').innerHTML) {
      //       alert('333333333')
      //       document.getElementById('features1').innerHTML = JSON.stringify(
      //         this.attrPopup,
      //         null,
      //         2
      //       )
      //     }

      //   }
      // });

      map.on("mouseenter", this.layerName, () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", this.layerName, () => {
        map.getCanvas().style.cursor = "";
      });

      // const apiKey = "AAPK1bba7e9a683d4a66ba4f8b526e74383cG_rqVYCp6T3mqvYBtj6JfIDb3tP1P-NgpF_cZiyKDXu8OsNu3IKcrnqQZEoE5YaJ";

      // map.on('click', function() {
      //   const baseUrl = "https://basemaps-api.arcgis.com/arcgis/rest/services/styles";
      //   const url = (name:any) => `${baseUrl}/${name}?type=style&token=${apiKey}`;
      //   const setBasemap = (name:any) => {
      //     // Instantiate the given basemap layer.
      //     map.setStyle(url(name.value));
      //   };

      //   setBasemap("ArcGIS:Streets");

      //   const basemapSelectorElement = document.getElementById("basemaps");
      //   basemapSelectorElement.addEventListener("change", (e) => {
      //     console.log('e', e)
      //     setBasemap(e.target);  
      //   });

      // });

    }, 200);
    // this.autoLogin();

    const queryParams = this.route.snapshot.queryParams;
    if (!Object.keys(queryParams).length) {
      this.router.navigate(['/layer-themes']);
    }
  }

  // getIntersectingObjects() {
  //   (document.querySelector('#mapid') as HTMLElement).style.zIndex = '0';
  //   (document.querySelector('#canvas-container') as HTMLElement).style.zIndex = '1';
  //   (document.querySelector('#canvas-container') as HTMLElement).style.zIndex = '1';
  //   const scene = new THREE.Scene();
  //   const canvas = document.querySelector('canvas');
  //   const sizes = {
  //     width: window.innerWidth,
  //     height: window.innerHeight,
  //   };
  //   const renderer = new THREE.WebGLRenderer({ canvas });
  //   renderer.setClearColor(0xffffff);
  //   renderer.setSize(sizes.width, sizes.height);
  //   const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000);
  //   camera.position.set(-20, 20, 25)
  //   const dirLight = new THREE.DirectionalLight(0xffffff, 0.85);
  //   dirLight.position.set(-10, 15, 15);
  //   dirLight.castShadow = true;
  //   dirLight.shadow.mapSize = new THREE.Vector2(1024, 1024);
  //   scene.add(dirLight);

  //   const gridSize = 10; // Размер сетки
  //   const gridDivisions = 10; // Количество разбиений сетки
  //   const gridHelper = new THREE.GridHelper(gridSize, gridDivisions);
  //   gridHelper.position.y = -1; // Положение сетки по оси Y
  //   scene.add(gridHelper);

  //   var mesh = [];
  //   const loader = new GLTFLoader();
  //   loader.load('https://dm.e-jambyl.kz/3dtest/test_attrs_5.glb', function (gltf) {
  //     const ggg = gltf.scene
  //     scene.add(ggg);
  //   });
  //   const mouse = new THREE.Vector2();
  //   const raycaster = new THREE.Raycaster();
  //   const originalMaterials: Map<THREE.Mesh, THREE.Material | THREE.Material[]> = new Map(); // Хранение исходных материалов объектов
  //   let selectedObject: THREE.Mesh | null = null; // Выбранный объект
  //   let originalMaterial: THREE.Material | THREE.Material[] | null = null; // Исходный материал выбранного объекта
  //   function onMouseClick(event) {
  //     event.preventDefault();
  //     mouse.x = event.clientX / sizes.width * 2 - 1
  //     mouse.y = - (event.clientY / sizes.height) * 2 + 1
  //     raycaster.setFromCamera(mouse, camera);
  //     const intersects = raycaster.intersectObject(scene, true);

  //     // Очищаем все предыдущие выбранные объекты
  //     originalMaterials.forEach((originalMaterial, mesh) => {
  //       if (Array.isArray(originalMaterial)) {
  //         mesh.material = originalMaterial[0]; // Выбираем первый материал из массива
  //       } else {
  //         mesh.material = originalMaterial; // Просто присваиваем исходный материал
  //       }
  //     });
  //     originalMaterials.clear(); // Очищаем словарь 
  //     if (intersects.length > 0) {
  //       var object = intersects[0].object.name;
  //       document.getElementById('features1').innerHTML = JSON.stringify(
  //         object,
  //         null,
  //         2
  //       )
  //       const selectedObject = intersects[0].object as THREE.Mesh;
  //       // Сохраняем исходный материал выбранного объекта и устанавливаем новый материал
  //       const originalMaterial = selectedObject.material;
  //       originalMaterials.set(selectedObject, originalMaterial);
  //       selectedObject.material = new THREE.MeshPhysicalMaterial({ color: 0xff0000 }); // Новый материал для выделения

  //     }

  //   };
  //   window.addEventListener('click', onMouseClick, false);
  //   const controls = new OrbitControls(camera, canvas);
  //   controls.enableDamping = true;
  //   window.addEventListener('resize', () => {
  //     sizes.width = window.innerWidth;
  //     sizes.height = window.innerHeight;
  //     camera.aspect = sizes.width / sizes.height;
  //     camera.updateProjectionMatrix();
  //     renderer.setSize(sizes.width, sizes.height);
  //     renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  //   });
  //   function animate() {
  //     controls.update();
  //     renderer.render(scene, camera);
  //     window.requestAnimationFrame(animate);
  //   }
  //   animate();

  // }

  exitMap() {
    (document.querySelector('#canvas-container') as HTMLElement).style.zIndex = '0';
    (document.querySelector('mat-drawer') as HTMLElement).style.zIndex = '2';
  }

  // autoLogin(): void {
  //   this.authService
  //     .login(`${environment.username}`, `${environment.password}`)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(
  //       (user: UserToken) => {
  //         this.authService.setToken(user ? user.access_token : null);
  //         this.authService.setUserId(user ? user.userId : null);
  //         // console.log('token token: ', this.authService.getToken());
  //         const queryParams = this.route.snapshot.queryParams;
  //         if (!Object.keys(queryParams).length) {
  //           this.router.navigate(['/layer-themes']);
  //         }
  //         console.log('user', user);
  //       },
  //       () => {
  //         // this.pending = false
  //       }
  //     );
  // }

  // d() {
  //   const drawnItems = new L.FeatureGroup();
  //   this.map.addLayer(drawnItems);
  //
  //   const illustrateControl = new L.Illustrate.Control({
  //     edit: {
  //       featureGroup: drawnItems
  //     }
  //   });
  //   this.map.addControl(illustrateControl);
  //
  //   drawnItems.addLayer(
  //     new L.Illustrate.Pointer(L.latLng(41.7868010411136, -87.60601043701172), [
  //       new L.Point(0, 0),
  //       new L.Point(100, -100),
  //       new L.Point(400, -100)
  //     ])
  //   );
  //   drawnItems.addLayer(
  //     new L.Illustrate.Pointer(
  //       L.latLng(41.80219068741082, -87.60648250579834),
  //       [new L.Point(0, 0), new L.Point(100, -100), new L.Point(400, -100)]
  //     )
  //   );
  //
  //   this.map.on('draw:created', (evt: any) => {
  //     // let type = evt.layerType,
  //     //   layer = evt.layer;
  //
  //     drawnItems.addLayer(evt.layer);
  //   });
  // }

  mapsId(): void {
    this.toolsShow = !this.toolsShow;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  // mapsId(visibility) {
  //   if (visibility === true) {
  //     (document.querySelector('#menu') as HTMLElement).style.display = 'block';
  //     (document.querySelector('.control1') as HTMLElement).style.display = 'block';
  //     (document.querySelector('.control') as HTMLElement).style.display = 'none';
  //   } else {
  //     (document.querySelector('#menu') as HTMLElement).style.display = 'none';
  //     (document.querySelector('.control') as HTMLElement).style.display = 'block';
  //     (document.querySelector('.control1') as HTMLElement).style.display = 'none';


  //   }
  // }
}

export interface UserToken {
  access_token: string;
  expires_in: number;
  jti: string;
  token_type: string;
  userId: number;
}
