<h2 mat-dialog-title></h2>
<mat-dialog-content class="mat-typography">
  <!-- KAZ -->
  <div *ngIf="siteSettings.language===language.KAZ">
    <p><strong>Жобаның мақсаты - </strong>
    <pre>
        Шымкент қаласының тұрғындарына электронды-анықтамалық карталарға қол жетімділікті қамтамасыз ету

Шымкент қаласының өңірлік геоақпараттық жүйесінің ашық картасында әлеуметтік инфрақұрылым объектілері белгіленген, бұл қаланың әртүрлі ұйымдары мен мекемелері, олардың байланыс деректері бар: ХҚКО, ауруханалар, дәріханалар, банктер, банкоматтар және басқалар.

Картада сауда-саттыққа қатысу шарттары және өтінімдерді қабылдау орындары егжей-тегжейлі сипатталған алдағы жер сауда-саттығы туралы ақпарат мерзімді түрде жарияланатын болады.

сіз қаланы дамытудың Бас жоспарымен, егжей-тегжейлі жоспарлау жобаларымен, оның ішінде қаланың қолданыстағы және жобаланған бөлігіндегі қызыл сызықтармен таныса аласыз.
Нысанды басқан кезде сіз таңдалған нысан туралы ақпарат ала аласыз.

ӨГАЖ бойынша қосымша ақпаратты келесі мекен-жай бойынша ала аласыз: "Шымкент қаласының сәулет, қала құрылысы және жер қатынастары басқармасы" ММ

Шымкент қаласы, Бәйдібек би даңғылы №126/1

+7 (7252) 39-44-04
      </pre>
  </div>

  <!-- RUS -->
  <div *ngIf="siteSettings.language===language.RUS || siteSettings.language===language.ENG">
    <p><strong>Цель проекта - </strong>
    <pre>
      предоставление доступа населению к электронно-справочным картам г. Шымкент

      На Открытой карте Региональной геоинформационной системе г. Шымкент отмечены объекты социальной инфраструктуры, это различные организаций и учреждения города с их контактными данными: ЦОНы, больницы, аптеки, банки, банкоматы и другие.
      
      На карте периодично будет публиковаться информация о предстоящих земельных торгах, с подробным описанием условий участия на торгах и местами приема заявок.
      
      Каждый желающий может ознакомиться с генеральным планом развития города, с проектами детальных планировок в том числе с красными линиями в существующей и проектируемой части города.
      
      При клике на объект, вы сможете получить информацию о выбранном объекте.
      
      Дополнительную информацию по РГИС, вы сможете получить по адресу: ГУ «Управление архитектуры, градостроительства и земельных отношений г. Шымкент»
      
      г.Шымкент, проспект Байдибек би №126/1
      
      +7 (7252) 39-44-04
      </pre>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'bottom.close' | translate}}</button>
</mat-dialog-actions>