import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {FlexLayoutModule} from '@angular/flex-layout';

import {MaterialModule} from './material/material.module';
import {SvgIconsService} from './services/svg-icons.service';
import { LogoComponent } from './components/logo/logo.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';



@NgModule({
  declarations: [LogoComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    MaterialModule,
    FlexLayoutModule,
    LogoComponent,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    SvgIconsService
  ]
})
export class SharedModule { }
