<div fxLayout="column" class="legends-wrapper" *ngIf="isLegendsVisible && legendNames.length > 0">

  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="flex-start flex-start" class="legend-content"
    *ngFor="let legendName of legendNames">
    <img [src]="legendName.src" [alt]="legendName.nameRu">
    <div>{{ legendName.nameRu }}</div>
  </div>
</div> <!-- legends name content -->

<div class="legend-container" fxLayout="row" fxLayoutAlign="space-evenly center" (click)="showLegendNames()">
  <img src="assets/icons/layers.svg" alt="Layers Icon">
  <span [translate]="'map.legend'">Легенда</span>
  <img *ngIf="isLegendsVisible" src="assets/icons/arrow-down.svg" alt="Arrow Down Icon">
  <img *ngIf="!isLegendsVisible" src="assets/icons/arrow_up.svg" alt="Arrow Up Icon">
</div> <!-- legends button itself -->
