<div class="header-container mat-elevation-z4" style="margin-bottom: 10px; background: #18A0FB;">

  <app-sh-logo></app-sh-logo>
</div>
<div class="header-container search-input">

  <form [formGroup]="searchForm">
       <mat-form-field appearance="outline" class="search-field" *ngIf="searchIsGoogle">
         <mat-icon matPrefix style="margin-left: 10px; color: #9AC124">search</mat-icon>
         <input matInput [matAutocomplete]="auto1" id="searchTextByGoogle" name="searchTextByGoogle"
                formControlName="searchTextByGoogle" placeholder="{{'header.search' | translate }}"
                style="width: 230px !important;">

         <mat-autocomplete #auto1="matAutocomplete" (optionSelected)='selectedPoint($event.option.value)'>
           <mat-option *ngFor="let street of filteredStreets" [value]="street.label">
             {{street.label}}
           </mat-option>
         </mat-autocomplete>
         <div style="position: absolute;right: 0px;top: 0px">
           <!-- <mat-checkbox title="{{'header.searchByAddress' | translate }}"
                         (change)="changeSearchType($event.checked)"
                         style="position:relative; display:inline-block; width:16px; bottom: 7.5px; right: 6px;"></mat-checkbox> -->
           <button mat-button mat-icon-button aria-label="Clear" style="width: 16px; display: inline-block;"
           (click)="clearGoogleSearch()">
             <mat-icon style="padding-bottom: 15px; color: red;">close</mat-icon>
           </button>
         </div>

       </mat-form-field>

    <!-- <mat-form-field appearance="outline" class="search-field"  *ngIf="!searchIsGoogle">
      <input matInput id="searchTextByAddress" name="searchTextByAddress" formControlName="searchTextByAddress"
        placeholder="{{'header.searchByAddress' | translate }}" (keyup.enter)="searchByAddressRegister()">
      <img src="../../../assets/icons/search.svg" matSuffix (click)="searchByAddressRegister()">

      <div  style="position: absolute;right: 10px;top: 5px">
           <mat-checkbox matSuffix [checked]="!searchIsGoogle" (change)="changeSearchType($event.checked)"
           ></mat-checkbox>        
      </div>

    </mat-form-field> -->
  </form>
  <!--<input class="search-input" placeholder="{{'header.search' | translate }}" [formControl]="control"-->
  <!--[matAutocomplete]="auto">-->
  <!--<mat-autocomplete #auto="matAutocomplete" (optionSelected)='selectedPoint($event.option.value)'>-->
  <!--<mat-option *ngFor="let street of filteredStreets" [value]="street.label">-->
  <!--{{street.label}}-->
  <!--</mat-option>-->
  <!--</mat-autocomplete>-->
</div>