import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    request = request.clone({
      headers: request.headers.append(
        'Accept-Language', 'ru'
      )
    });
    if (token) {
      const req = request.clone({
        headers: request.headers.append(
          'Authorization', `Bearer ${token}`,
        )
      });
      return next.handle(req);
    }
    return next.handle(request);
  }
}
