import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ILayerGroup } from '../models';
import { Layer } from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class LayersApiService {
  currentLayerGroupId$ = new BehaviorSubject<ILayerGroup>(null);
  private layer: Layer;

  constructor(
    private http: HttpClient,
  ) {
  }

  getLayerThemesPermissions(): any {
    return this.http.get(`${environment.api}/open/treelayer`);
  }


  getAttrLayers3D(layerName3d, Name3d): any {
    console.log('layerName3d', layerName3d);
    console.log('Name3d', Name3d);
  if (!layerName3d) {
    alert('Слой не найдено')
    return;
  }
  // http://geo.7su.kazgisa.kz/dutymap/api/open/test_attrs_5/objects/24-NG-040302-CH60-HN003?geoserverId=1
  // http://localhost:4400/dutymap/api/open/test_attrs_5/objects/F-040301001?geoserverId=1
  return this.http.get(`${environment.api}/open/${layerName3d}/objects/${Name3d}?geoserverId=1`);
  } 

  getLayerGroupsByThemeId(themeId: number): any {
    if (!themeId) {
      return;
    }
    return this.http.get(`${environment.api}/layers/groups?themeId=${themeId}`);
  }

  getLayersByLayerGroupId(layerGroupId: number): any {
    if (!layerGroupId) {
      return;
    }
    return this.http.get(`${environment.api}/layers?layerGroupId=${layerGroupId}`);
  }

  getLayerObjects(layerName: string, body: object = {}, params: string = ''): any {
    if (!layerName) {
      return;
    }
    const paramsUrl = params && params !== '' ? params : '';
    const url = `${environment.api}/layers/${layerName}/objects/filter` + paramsUrl;
    return this.http.post(url, body);
  }

  getLayerObject(layerName: string, objectId: number, geoserverId: number): any {
    if (!layerName || !objectId) {
      return;
    }
    const url = `${environment.api}/layers/${layerName}/objects/${objectId}?geoserverId=${geoserverId}`;
    return this.http.get(url);
  }

  getLayerAttributes(layerName: string, geoserverId: number): any {
    if (!layerName) {
      return;
    }
    return this.http.get(`${environment.api}/open/${layerName}/attributes?geoserverId=${geoserverId}`);
  }

  getLayerInfo(url): Observable<any> {
    return this.http.get<any>(url);
  }

  setLayer(layer: Layer): void {
    this.layer = layer;
    // this.currentLayerGroupId$.next(layer);
  }

  getLayer(): any {
    return this.layer;
  }

  getLandGeomByAddress(cadNum) {
    const url =
    environment.api +
      `/open/gzkattributes?cad_number=${cadNum}`;
      // const options = {
      //   headers: new HttpHeaders({
      //     // 'Authorization': 'Bearer ' + token,
      //     'Content-Type': 'application/json'
      //   }),
      // }
    return this.http.get(url);
  }
}
