import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {InfoDialogComponent} from './info-dialog/info-dialog.component';
import {ContactDialogComponent} from './contact-dialog/contact-dialog.component';
import {InfoPlayDialogComponent} from './info-play-dialog/info-play-dialog.component';
import {LayersManagerService} from '../../shared/services/layers-manager.service';
import {TranslateService} from '@ngx-translate/core';
import siteSettings from '../../shared/config/site-settings';
import {Language} from '../../shared/config/language';

@Component({
  selector: 'app-sh-bottom',
  templateUrl: './bottom.component.html',
  styleUrls: ['./bottom.component.scss']
})
export class BottomComponent implements OnInit {

  language = Language;

  constructor(public dialog: MatDialog,
              private layersManagerService: LayersManagerService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  selectLanguage(language: number): void {
    siteSettings.language = language;
    localStorage.setItem('eJambylLanguage', JSON.stringify(language));
    let lang = 'ru';
    if (language === this.language.KAZ) {
      lang = 'kk';
    }
    if (language === this.language.ENG) {
      lang = 'en';
    }
    this.translate.use(lang);
  }

  isActive(language: number): boolean {
    return siteSettings.language === language;
  }

  info(): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {width: '550px'});

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  infoPlayDialog(): void {
    const dialogRef = this.dialog.open(InfoPlayDialogComponent, {
      height: '60%',
      width: '40%',
      hasBackdrop: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
    (document.querySelector('.infoPlay') as HTMLElement).style.pointerEvents = 'none';
  }

  contact(): void {
    const dialogRef = this.dialog.open(ContactDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  closeLayers(): void {
    // this.layersManagerService.addLayer('crime', '1=1');
    this.layersManagerService.clearSearchMarkerByAddressRegister();
    siteSettings.isClearAllLyrs = true;
    // this.layersManagerService.removeAllVisibleLayers();
  }

  goToLink(url: string): void {
    window.open(url, '_blank');
  }
}

