import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ILayersLegend } from 'src/app/shared/models';

import { LayersManagerService } from 'src/app/shared/services/layers-manager.service';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
})
export class LegendComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  constructor(private layersManagerService: LayersManagerService) {}

  legendNames: ILayersLegend[] = new Array();
  isLegendsVisible = false;

  ngOnInit(): void {
    this.layersManagerService.visibleLayers$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.legendNames.length = 0;
        data.forEach((l) => {
          this.legendNames.push({
            layerName: l.options.layers,
            nameRu: l.options.nameRu,
            src: this.getLegendSrc(l),
          });
        });
      });
  }

  getLegendSrc(layer: any): string {
    return (
      layer._url +
      `&REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=
      ${layer.options.layers}`
    );
  }

  showLegendNames(): void {
    this.isLegendsVisible = !this.isLegendsVisible;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
