import { Injectable } from '@angular/core';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class SvgIconsService {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'left-arrow',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/left-arrow.svg')
    );
    iconRegistry.addSvgIcon(
      'right-arrow',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/right-arrow.svg')
    );
    // languages
    iconRegistry.addSvgIcon(
      'ru',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/sh-bottom/ru.svg')
    );
    iconRegistry.addSvgIcon(
      'kaz',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/sh-bottom/kaz.svg')
    );
    iconRegistry.addSvgIcon(
      'qaz',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/sh-bottom/qaz.svg')
    );
    iconRegistry.addSvgIcon(
      'left-arrow-circle',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/left-arrow-circle.svg')
    );
    iconRegistry.addSvgIcon(
      'diagonal-expand',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/diagonal-expand.svg')
    );
    iconRegistry.addSvgIcon(
      'x-mark',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/x-mark.svg')
    );
  }
}
