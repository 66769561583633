<div fxLayout="row" fxLayoutAlign="space-between center">
  <span style="color: #18A0FB;">{{siteSettings.language===language.KAZ ? 'ЕТЖЖ шекаралары' : 'Границы ПДП'}}</span>
  <button mat-icon-button style="color: #18A0FB;" (click)="expandPdp()">
    <mat-icon *ngIf="visibleContent">expand_more</mat-icon>
    <mat-icon *ngIf="!visibleContent">expand_less</mat-icon>
  </button>

</div>

<ng-container *ngIf="items.length > 0 && visibleContent">
  <ng-container *ngFor="let item of items">
    <ng-container *ngIf="item.description_kz === 'ЕТЖЖ шекарасы' && item.pdp_name !== undefined">
      <!-- <p *ngIf="siteSettings.language===language.KAZ">{{item.short_name}}</p>
    <p *ngIf="siteSettings.language===language.RUS">{{item.name}}</p> -->

      <div *ngIf="visibleChek === true" style="display: block; place-content: center space-between;
      align-items: center; box-sizing: border-box;">
        <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div *ngIf="item.description_kz == 'ЕТЖЖ шекарасы'">
          <mat-checkbox
            matTooltip="{{siteSettings.language===language.KAZ ? 'ЕТЖЖ қабатын қосу үшін құсбелгіні қойыңыз' : 'Поставьте галочку чтобы включить слой пдп'}}"
            class="show-pdp" [ngModel]="item?.isChecked" (change)="change($event.checked, item?.pdp_name)">
            <span *ngIf="item.isChecked === false && siteSettings.language===language.KAZ"
              style="white-space: break-spaces;">{{item.short_name}}</span>
            <span *ngIf="item.isChecked === false && siteSettings.language===language.RUS"
              style="white-space: break-spaces;">{{item.name}}</span>
            <span *ngIf="item.isChecked === true && siteSettings.language===language.KAZ">ЕТЖЖ өшіру</span>
            <span *ngIf="item.isChecked === true && siteSettings.language===language.RUS">Скрыть ПДП</span>
          </mat-checkbox>
        </div>
        <button mat-icon-button color="warn" style="margin-right: -1.2vh;" (click)="handleClose(item?.pdp_name)">
          <mat-icon>cancel</mat-icon>
        </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutGap="4%" fxLayoutAlign="space-between center">
            <!-- <div fxLayout="column" fxFlex="100%" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="4%"
              fxLayoutAlign="start center" style="height: 30px;"> -->
              <!-- <span class="transparency">{{'layer.transparency' | translate}}: {{sliderVal}}</span> -->
              <mat-slider [max]="max" [min]="min" tickInterval="auto" [step]="step" (input)="changeValue($event, item)">
              </mat-slider>
            <!-- </div> -->
          </div>
          <div fxLayout="row" fxLayoutGap="4%" fxLayoutAlign="space-between center">
            <!-- <div fxLayout="column" fxFlex="100%" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="4%"
              fxLayoutAlign="start center"> -->
              <img src="../../../../assets/icons/map-thematic/bring_closer.svg" (click)="changeZoom($event, item)">
            <!-- </div> -->
          </div>
        </div>

      </div>
      <hr />
    </ng-container>
  </ng-container>
</ng-container>
