import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { LegendComponent } from './legend/legend.component';
import { MapLayersComponent } from './map-layers/map-layers.component';
import { ZoomInOutComponent } from './zoom-in-out/zoom-in-out.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [LegendComponent, MapLayersComponent, ZoomInOutComponent],
  imports: [CommonModule, SharedModule,  TranslateModule],
  exports: [LegendComponent, MapLayersComponent, ZoomInOutComponent],
})
export class MapControlsModule {}
