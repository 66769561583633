<h2 mat-dialog-title></h2>
<mat-dialog-content class="mat-typography">
  <div *ngIf="siteSettings.language===language.KAZ">
    <p><strong>БАЙЛАНЫС</strong></p>
    <pre>
      "Сәулет, қала құрылысы және жер қатынастары басқармасы" ММ»

Мекен-жайы: Шымкент қаласы, Бәйдібек би даңғылы №126/1
Телефоны: +7 (7252) 39-44-04
    </pre>
  </div>
  <div *ngIf="siteSettings.language===language.RUS || siteSettings.language===language.ENG">
    <p><strong>КОНТАКТЫ</strong></p>
    <pre>
      ГУ «Управление архитектуры, градостроительства и земельных отношений»

Адрес: г.Шымкент, проспект Байдибек би №126/1
Телефон: +7 (7252) 39-44-04
    </pre>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'bottom.close' | translate}}</button>
</mat-dialog-actions>