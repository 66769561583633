import { Component, Inject, Input, OnInit } from '@angular/core';
import {Language} from '../../../shared/config/language';
import siteSettings from '../../../shared/config/site-settings';
import { LayersManagerService } from 'src/app/shared/services/layers-manager.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILayerGroup } from 'src/app/shared/models';


@Component({
  selector: 'app-pdp-dialog',
  templateUrl: './pdp-dialog.component.html',
  styleUrls: ['./pdp-dialog.component.scss']
})
export class PdpDialogComponent implements OnInit {
  visibleContent = true;
  visibleChek = false;
  pdpVisible;
  @Input() layerGroup: ILayerGroup;

  siteSettings = siteSettings;
  language = Language;
  items;
  pdpContent;
  nameLayer: string[] = [];
  ischecked = false;
  sliderVal = 0;

  constructor(
    public dialogRef: MatDialogRef<PdpDialogComponent>,
    private managerSvc: LayersManagerService,
    @Inject(MAT_DIALOG_DATA) public data: { photoPath: string, id: string },
  ) { }

  ngOnInit(): void {
      this.pdpContent = this.data;
      // this.pdpContent.forEach((item, index) => {
        this.items = this.pdpContent;
        this.nameLayer = this.pdpContent?.map(itm => itm.pdp_name);
      // });


      if (this.nameLayer.length === 0) {
        this.visibleChek = false;
        this.dialogRef.close();
      } else {
        this.visibleChek = true;
      }
  }



  change(checked, layerName): void {
    sessionStorage.setItem('pdpVisible', this.pdpVisible = 'false');
    this.managerSvc.editableLayers.clearLayers();
    if (checked) {
      this.ischecked = checked;
      this.managerSvc.addLayer(layerName, '1=1');
      this.visibleChek = true;
    } else {
      this.managerSvc.removeLayer(layerName);
    }

  }

  handleClose(layerName) {
    this.items = this.items.filter(itm => itm.pdp_name !== layerName);
    this.managerSvc.removeLayer(layerName);
    if (this.items.length === 0) {
      sessionStorage.setItem('pdpVisible', this.pdpVisible = 'true');
      this.dialogRef.close();
    }
  }
  
  expandPdp() {
    if (this.visibleContent === true) {
      this.visibleContent = false;
    } else {
      this.visibleContent = true;
    }
  }

  changeValue(event, item): void {
    this.sliderVal = event.value;
    this.managerSvc.changeLayerGroupOpacity(item, (this.sliderVal / 100));
  }

  changeZoom(event, item): void {
    this.managerSvc.changeLayerZoom(item);
  }
}