import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';
// import maplibregl from 'maplibre-gl';
import * as maplibregl from 'maplibre-gl';
// import { MaplibreExportControl, Size, PageOrientation, Format, DPI} from "@watergis/maplibre-gl-export";
// import '@watergis/maplibre-gl-export/css/styles.css';
// import MeasuresControl from 'maplibre-gl-measures';
// import RulerControl from 'maplibre-gl-controls';
// import { RulerControl } from 'mapbox-gl-controls';
// import { MaplibreExportControl, Size, PageOrientation, Format, DPI } from "@watergis/maplibre-gl-export";
// import '@watergis/maplibre-gl-export/css/styles.css';
import { Camera } from "three";
// import "mapbox-gl-style-switcher/styles.css";
import MeasuresControl from 'maplibre-gl-measures';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Light, Material, PerspectiveCamera, Scene } from 'three';
import { Vector3, Matrix4 } from 'three';
// import BasemapsControl from 'maplibre-gl-basemaps';
// import 'maplibre-gl-basemaps/lib/basemaps.css';
// import 'maplibre-gl/dist/maplibre-gl.css';

// import

// import {GeoSearchControl, SearchControl} from 'leaflet-geosearch/dist';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private map: maplibregl.Map;
  // private Camera: Camera;
  // map: maplibregl.Map;
  currentLayer: L.TileLayer;
  public editableLayers: L.FeatureGroup = new L.FeatureGroup();

  private drawActivated = false;
  private drawControls: L.Control.Draw = null;
  layerName;

  googleRoadLayer = L.tileLayer(
    'https://mt0.google.com/vt/lyrs=m&hl=ru&x={x}&y={y}&z={z}&s=Galileo',
    {
      maxZoom: 21,
      minZoom: 9,
      attribution: ``,
    }
  );
  googleHybridLayer = L.tileLayer(
    'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
    {
      maxZoom: 21,
      minZoom: 9,
      attribution: ``,
    }
  );
  osmLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 21,
    minZoom: 9,
    attribution: ``,
  });
  googleSat = L.tileLayer('https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
    maxZoom: 21,
    minZoom: 9,
    attribution: ``
  });
  white = L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
    maxZoom: 21,
    minZoom: 9,
    attribution: ``
  });
  whitemap = L.tileLayer('', {
    maxZoom: 21,
    minZoom: 9,
    attribution: ``
  });
  TwoGis = L.tileLayer('https://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}', {
    maxZoom: 18,
    minZoom: 9,
    attribution: ``
  });
  ngOnInit: any;
  // blue = L.tileLayer('https://s3.amazonaws.com/elevation-tiles-prod/normal/{z}/{x}/{y}.png', {
  //   maxZoom: 21,
  //   // minZoom: 7,
  //   attribution: ``
  // });


  constructor() {
  }

  getMap(): maplibregl.Map {
    // this.map.on('click', (e: any) => {
    //   this.layersManagerService.showGetFeatureInfo(this.map, e.latlng);
    // });
    return this.map;
  }

  public setMap(mapIn: maplibregl.Map) {
    this.map = mapIn;
  }





  public initMap(target: string): maplibregl.Map {
    console.log('target raes', target);

    // const map = L.map(target, {
    //   zoomControl: false,
    //   attributionControl: false,
    //   crs: L.CRS.EPSG3857,
    //   preferCanvas: true
    // }).setView([44.850, 76.363], 7);
    const apiKey = "iFWjZcNntAHJXpoTJmln";
    // const apiKey = "AAPK1bba7e9a683d4a66ba4f8b526e74383cG_rqVYCp6T3mqvYBtj6JfIDb3tP1P-NgpF_cZiyKDXu8OsNu3IKcrnqQZEoE5YaJ";
    const basemapEnum = "topo-v2";
    const map = new maplibregl.Map({
      container: target, // the id of the div element
      // style: `https://basemaps-api.arcgis.com/arcgis/rest/services/styles/${basemapEnum}?type=style&token=${apiKey}`,
      style: `https://api.maptiler.com/maps/topo-v2/style.json?key=iFWjZcNntAHJXpoTJmln`,
      zoom: 14, // starting zoom
      minZoom: 6,
      maxZoom: 24,
      attributionControl: false,
      center: [51.8621, 47.1100], // starting location [longitude, latitude]
      // pitch: 360,
      antialias: true,
      hash: true,
      // style: {
      //   version: 8,
      //   sources: {
      //   osm: {
      //     type: 'raster',
      //     tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
      //     tileSize: 256,
      //     attribution: '&copy; OpenStreetMap Contributors',
      //     maxzoom: 19,
      //   },
      //   terrainSource: {
      //     type: 'raster-dem',
      //     url: 'http://192.168.1.57:65500/data/test.json',
      //     tileSize: 256,
      //   },
      //   hillshadeSource: {
      //     type: 'raster-dem',
      //     url: 'http://192.168.1.57:65500/data/test.json',
      //     tileSize: 256,
      //   },
      // },
      // layers: [
      //   {
      //     id: 'osm',
      //     type: 'raster',
      //     source: 'osm',
      //   },
      //   {
      //     id: 'hills',
      //     type: 'hillshade',
      //     source: 'hillshadeSource',
      //     layout: {visibility: 'visible'},
      //     paint: {'hillshade-shadow-color': '#473B24'},
      //   },
      // ],
      //   terrain: {
      //     source: "terrainSource",
      //     exaggeration: 1,
      //   },
      // },
      // maxZoom: 18,
      maxPitch: 85,
    });

    // parameters to ensure the model is georeferenced correctly on the map

    // var modelOrigin = {lon: 69.63109723359987, lat: 42.305902876084645};
    // var modelAltitude = 0;
    // var modelRotate = [Math.PI / 2, 0, 0];
    // var modelAsMercatorCoordinate = maplibregl.MercatorCoordinate.fromLngLat(
    //     modelOrigin,
    //     modelAltitude
    // );
    // // transformation parameters to position, rotate and scale the 3D model onto the map
    // var modelTransform = {
    //     translateX: modelAsMercatorCoordinate.x,
    //     translateY: modelAsMercatorCoordinate.y,
    //     translateZ: modelAsMercatorCoordinate.z,
    //     rotateX: modelRotate[0],
    //     rotateY: modelRotate[1],
    //     rotateZ: modelRotate[2],
    //     /* Since our 3D model is in real world meters, a scale transform needs to be
    //      * applied since the CustomLayerInterface expects units in MercatorCoordinates.
    //      */
    //     scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
    // };
    // map.on('style.load', function () {
    //     map.addLayer({
    //       id: '3d-model',
    //       type: 'custom',
    //       renderingMode: '3d',
    //       onAdd: function (map, gl) {

    //           this.scene = new THREE.Scene();
    //           // create two three.js lights to illuminate the model
    //           var directionalLight = new THREE.DirectionalLight(0xffffff);
    //           directionalLight.position.set(0, -70, 100).normalize();
    //           this.scene.add(directionalLight);
    //           var directionalLight2 = new THREE.DirectionalLight(0xffffff);
    //           directionalLight2.position.set(0, 70, 100).normalize();
    //           this.scene.add(directionalLight2);
    //           // use the three.js GLTF loader to add the 3D model to the three.js scene
    //           var loader = new GLTFLoader();
    //           loader.load(
    //               'https://dm.e-jambyl.kz/3dtest/FabConvert.com_office_fbx.glb',
    //               function (gltf) {
    //                   this.scene.add(gltf.scene);
    //               }.bind(this)
    //           );
    //           this.map = map;
    //           // use the MapLibre GL JS map canvas for three.js
    //           this.renderer = new THREE.WebGLRenderer({
    //               canvas: map.getCanvas(),
    //               context: gl,
    //               antialias: true
    //           });
    //           this.renderer.autoClear = false;
    //       },
    //       render: function (gl, matrix) {
    //           const camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 0.01, 10 );
    //           camera.position.z = 1;
    //           var rotationX = new THREE.Matrix4().makeRotationAxis(
    //               new THREE.Vector3(1, 0, 0),
    //               modelTransform.rotateX
    //           );
    //           var rotationY = new THREE.Matrix4().makeRotationAxis(
    //               new THREE.Vector3(0, 1, 0),
    //               modelTransform.rotateY
    //           );
    //           var rotationZ = new THREE.Matrix4().makeRotationAxis(
    //               new THREE.Vector3(0, 0, 1),
    //               modelTransform.rotateZ
    //           );
    //           var m = new THREE.Matrix4().fromArray(matrix);
    //           var l = new THREE.Matrix4()
    //               .makeTranslation(
    //                   modelTransform.translateX,
    //                   modelTransform.translateY,
    //                   modelTransform.translateZ
    //               )
    //               .scale(
    //                   new THREE.Vector3(
    //                       modelTransform.scale,
    //                       -modelTransform.scale,
    //                       modelTransform.scale
    //                   )
    //               )
    //               .multiply(rotationX)
    //               .multiply(rotationY)
    //               .multiply(rotationZ);
    //           camera.projectionMatrix = m.multiply(l);
    //           this.renderer.resetState();
    //           this.renderer.render(this.scene, camera);
    //           this.map.triggerRepaint();
    //       }
    //   });
    // });





    map.on('mouseout', function() {
      const baseUrl = "https://api.maptiler.com/maps";
      const url = (name:any) => `${baseUrl}/${name}/style.json?key=${apiKey}`;
      const setBasemap = (name:any) => {
        // Instantiate the given basemap layer.
        map.setStyle(url(name.value));
      };
      
      setBasemap(`streets`);
      
      const basemapSelectorElement = document.getElementById("basemaps");
      basemapSelectorElement.addEventListener("change", (e) => {
        console.log('e', e)
        setBasemap(e.target);  
      });
      this.map = map.addControl(this.map);

    });

    // const layerList = document.getElementById("menu");
    // const inputs = layerList.getElementsByTagName("input");
    // // const button = document.getElementById("bt_pitch");
    // function switchLayer(layer) {
    //   console.log('layer', layer);
    //   console.log('apiKey', apiKey);


    //   const layerId = layer.target.id;
    //   // map.setStyle("https://geoserveis.icgc.cat/contextmaps/" + layerId + ".json");
    //   map.setStyle("https://api.maptiler.com/maps/" + layerId + "/tiles.json?key=iFWjZcNntAHJXpoTJmln");
    //   // map.setStyle("https://basemaps-api.arcgis.com/arcgis/rest/services/styles/" + layerId +"?type=style&token=" + apiKey+"");
    // }


    map.addControl(new maplibregl.NavigationControl({
      visualizePitch: true,
      showZoom: true,
      showCompass: true

    }));
    map.addControl(new maplibregl.ScaleControl({

    }),);
    map.addControl(new maplibregl.FullscreenControl({

    }));

  //   map.addControl(new MaplibreExportControl({
  //     PageSize: Size.A3
  // }), 'top-right');

//   (new MaplibreExportControl({
//     PageSize: Size.A3
    
// }), 'top-right');

    // map.addControl(
    //   new maplibregl.TerrainControl({
    //     source: 'terrainSource',
    //     exaggeration: 1

    //   })
    // );

    // map.addControl(new BasemapsControl({}), 'top-right');


    map.addControl(new MeasuresControl({
      lang: {
        areaMeasurementButtonTitle: 'Линия',
        lengthMeasurementButtonTitle: 'Полигон',
        clearMeasurementsButtonTitle: 'Удалить',
      },
      units: 'metric', //or imperial, the default
      style: {
        text: {
          radialOffset: 0.9,
          letterSpacing: 0.05,
          color: 'blue',
          haloColor: 'blue',
          haloWidth: 0,
        },
        common: {
          midPointRadius: 3,
          midPointColor: 'blue',
          midPointHaloRadius: 5,
          midPointHaloColor: 'blue',
        },
        areaMeasurement: {
          fillColor: 'blue',
          fillOutlineColor: 'blue',
          fillOpacity: 0.01,
          lineWidth: 2,
        },
        lengthMeasurement: {
          lineWidth: 2,
          lineColor: "blue",
        },
      }
    }));

  //   map.addControl(new MaplibreExportControl({
  //     PageSize: Size.A3,
  //     PageOrientation: PageOrientation.Portrait,
  //     Format: Format.PNG,
  //     DPI: DPI[96],
  //     Crosshair: true,
  //     PrintableArea: true,
  //     Local: 'en'
  // }), 'top-right');

    // map.addControl(new RulerControl(), 'top-right');
    // map.addControl(
    //   new maplibregl.GeolocateControl({
    //     positionOptions: {
    //       enableHighAccuracy: true
    //     },
    //     trackUserLocation: true
    //   })
    // );

    //   map.addControl(new MaplibreExportControl({
    //     PageSize: Size.A3,
    //     PageOrientation: PageOrientation.Portrait,
    //     Format: Format.PNG,
    //     DPI: DPI[96],
    //     Crosshair: true,
    //     PrintableArea: true,
    //     Local: 'en'
    // }), 'top-right');

    // map.addControl(
    //   new maplibregl.AttributionControl({
    //     compact: true
    //   })
    // );

    // map.addControl(new MeasuresControl({
    //   lang: {
    //     areaMeasurementButtonTitle: 'Measure area',
    //     lengthMeasurementButtonTitle: 'Measure length',
    //     clearMeasurementsButtonTitle: 'Clear measurements',
    //   },
    //   units: 'imperial', //or metric, the default
    //   style: {
    //     text: {
    //       radialOffset: 0.9,
    //       letterSpacing: 0.05,
    //       color: '#D20C0C',
    //       haloColor: '#fff',
    //       haloWidth: 0,
    //     },
    //     common: {
    //       midPointRadius: 3,
    //       midPointColor: '#D20C0C',
    //       midPointHaloRadius: 5,
    //       midPointHaloColor: '#FFF',
    //     },
    //     areaMeasurement: {
    //       fillColor: '#D20C0C',
    //       fillOutlineColor: '#D20C0C',
    //       fillOpacity: 0.01,
    //       lineWidth: 2,
    //     },
    //     lengthMeasurement: {
    //       lineWidth: 2,
    //       lineColor: "#D20C0C",
    //     },
    //   }
    // }), "top-left");
    // map.addControl(new MapboxStyleSwitcherControl());

    // this.currentLayer = this.googleHybridLayer.addTo(map);


    // map.on("load", function () {
    //   map.addControl(new maplibregl.NavigationControl());
    //   map.addControl(
    //     new maplibregl.AttributionControl({
    //       compact: true
    //     })
    //   );
    //   map.addControl(
    //     new maplibregl.GeolocateControl({
    //       positionOptions: {
    //         enableHighAccuracy: true,
    //         // watchPosition: true
    //       }
    //     })
    //   );
    // });

    // const layerList = document.getElementById("menu");
    // const inputs = layerList.getElementsByTagName("input");
    // // const button = document.getElementById("bt_pitch");
    // function switchLayer(layer) {
    //   console.log('layer', layer);
    //   console.log('apiKey', apiKey);


    //   const layerId = layer.target.id;
    //   // map.setStyle("https://geoserveis.icgc.cat/contextmaps/" + layerId + ".json");
    //   map.setStyle("https://api.maptiler.com/maps/" + layerId + "/tiles.json?key=iFWjZcNntAHJXpoTJmln");
    //   // map.setStyle("https://basemaps-api.arcgis.com/arcgis/rest/services/styles/" + layerId +"?type=style&token=" + apiKey+"");
    // }
    // function canviaPerspectiva() {
    //   let pitch = parseInt(map.getPitch());
    //   pitch == 60 ? (pitch = 0) : (pitch = pitch + 30);
    //   map.easeTo({
    //     pitch: pitch
    //   });
    // }
    // for (let i = 0; i < inputs.length; i++) {
    //   inputs[i].onclick = switchLayer;
    // }
    // button.onclick = canviaPerspectiva;



    // map.once("load", () => {
    //   // This code runs once the base style has finished loading.

    //   map.addSource("builds_3dtest", {
    //     type: "vector",
    //     tiles: [
    //       "http://dm.7su.kazgisa.kz/tileserver/data/builds_3dtest/{z}/{x}/{y}.pbf"
    //     ]
    //   });

    //   map.addLayer({
    //     id: "builds_3dtest",
    //     type: "fill-extrusion",
    //     source: "builds_3dtest",
    //     "source-layer": "builds_3dtest",
    //     paint: {
    //       "fill-extrusion-height":["case",["has","floor"],["get","floor"],0],
    //       "fill-extrusion-color": "hsl(35, 8%, 85%)"
    //     }
    //   });

    // });

    map.on('mousemove', function (e) {
      document.getElementById('info').innerHTML =
        // e.point is the x, y coordinates of the mousemove event relative
        // to the top-left corner of the map
        // JSON.stringify(e.point) +
        // '<br />' +
        // e.lngLat is the longitude, latitude geographical position of the event
        // let chea = cheat.slice(0, -1);
        'N:' + JSON.stringify(e.lngLat.wrap().lat).slice(0, -10) + ' ' + 'E:' + JSON.stringify(e.lngLat.wrap().lng).slice(0, -10);
    });


    // if (sessionStorage.getItem('layerName')) {
    //    this.layerName = sessionStorage.getItem('layerName');
    // }
    //   map.on("click", this.layerName, (e) => {
    //   console.log('e result >>>:', e);
    //   const builds_3dtest = e.features[0];
    //   console.log('builds_3dtest', builds_3dtest);

    //   new maplibregl.Popup()
    //     .setHTML(`<h3>1.</h3><p>${builds_3dtest.properties.full_path_kz}</p>
		//   <h3>2.</h3><p>${builds_3dtest.properties.name_kz_s_geonims}</p>`)
    //     .setLngLat(e.lngLat)
    //     .addTo(map);

    // });



    map.on("mouseenter", this.layerName, () => {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", this.layerName, () => {
      map.getCanvas().style.cursor = "";
    });

    // this.currentLayer = this.googleHybridLayer.addTo(map);
    // map.addLayer(this.editableLayers);
    // this.currentLayer = this.districtBoundariesLayer.addTo(map);

    // const scaleControl = L.control.scale({
    //   position: 'bottomright',
    //   metric: true,
    //   imperial: false,
    // });
    // map.addControl(scaleControl);



    this.map = map;
    // this.getMap(this.map);
    return map;
  }

  public clearEditableLayers(): void {
    if (this.editableLayers.getLayers().length > 0) {
      this.editableLayers.clearLayers();
    }
  }

  public getDrawControls(): any {
    return this.drawControls;
  }

  // public setDrawControls(drawControls: L.Control.Draw): void {
  //   this.drawControls = drawControls;
  // }
}
