<mat-drawer-container class="main-container" autosize >
  <mat-drawer #drawer class="left-sidenav mat-drawer-opened" [opened]="true" mode="side">
    <app-lsn-header></app-lsn-header>
    <div class="center-container">
      <router-outlet></router-outlet>
    </div>
    <app-sh-bottom></app-sh-bottom>
  </mat-drawer>

  <div class="right-sidenav-content">
    <div class="toggle-left-sidenav" fxLayoutAlign="center" (click)="drawer.toggle();toggleLeftSide()" [ngStyle]="{'margin-left.px': width}">
      <!--      [ngStyle]="{'margin-left.px': width}"-->
      <mat-icon class="toto" *ngIf="leftSidenavOpened" svgIcon="left-arrow" aria-hidden="false" aria-label="toggle button">
      </mat-icon>
      <mat-icon class="toto" *ngIf="!leftSidenavOpened" svgIcon="right-arrow" aria-hidden="false"
                aria-label="toggle button"></mat-icon>
    </div>
    <app-legend class="legend" [ngStyle]="{'margin-left.px': width}"></app-legend>
    <div class="zoom-controls" fxLayout="column">
      <app-zoom-in-out></app-zoom-in-out>
    </div>
    <!-- <div class="zoom-controls" fxLayout="column"> -->


  
      <div class="control1"  fxLayoutAlign="center center" (click)="mapsId()"><mat-icon> layers</mat-icon>
      </div>   
      <div id="basemaps-wrapper" class="locationMap" *ngIf="toolsShow">
        <select id="basemaps">
          <option value="openstreetmap">OpenStreetMap</option>
          <option value="streets">Streets</option>
          <option value="basic-v2">Basic</option>
          <option value="satellite">Satellite</option>
          <option value="topo-v2">Topo</option>
        </select>
      </div>



    <div class="bottom-right-ctrls" fxLayout="row" fxLayoutAlign="flex-start flex-end" style="
      background: white;
      height: 30px;
      padding: 0px 10px;">
      <!-- <app-map-layers class="map-layers"></app-map-layers> -->
      <pre id="info" style="background: white; margin: 4px;"></pre>
    </div>
    
    <!-- <div id='div_pitch' class="mapboxgl-ctrl-group mapboxgl-ctrl"></div> -->
    <div id="canvas-container" style="z-index: 0; display: flex;">
      <canvas id="canvas-overlay"></canvas>
      <!-- <div id="attrMod"> -->
        <!-- <div fxLayout="row" fxLayoutAlign="space-between center" > -->
          <!-- <button mat-raised-button color="primary" (click)="exitMap()">
            <mat-icon> map</mat-icon> Карта
          </button> -->
          <!-- <button mat-stroked-button fxFlex="45">
            <mat-icon>settings</mat-icon>
          </button> -->
        <!-- </div> -->
        <!-- <p id="features3dAttr"></p> -->
        <div class="card">
          <div class="tools">
            <button mat-raised-button color="primary" (click)="exitMap()">
              <mat-icon> map</mat-icon> Карта
            </button>
            <!-- <div class="circle">
              <span class="red box"></span>
            </div>
            <div class="circle">
              <span class="yellow box"></span>
            </div>
            <div class="circle">
              <span class="green box"></span>
            </div> -->
          </div>
          <div class="card__content">
            <!-- <button mat-raised-button color="primary" (click)="exitMap()">
              <mat-icon> map</mat-icon> Карта
            </button> -->
            <!-- <hr> -->
            <p class="content" id="features3dAttr">
            </p>
          </div>
        </div>
      <!-- </div> -->
      <div id="gui-container"></div>
    </div>
    <div id="mapid" style="z-index: 1;"></div>

    <!-- <pre id="features">
          <pre id="features0"></pre>   
    </pre> -->

    <div id="features">
      <!-- <div class="mat-typography">
          <div>
            <pre id="features0"></pre>
          </div>
        </div> -->
      <!-- <div>
          <button mat-raised-button color="warn" (click)="popup()">{{'bottom.close' | translate}}</button>
        </div> -->
      <div>
        <button mat-icon-button color="warn" (click)="popup()" style="margin: -10px 0 0 -12px;"><mat-icon>close</mat-icon></button>
        <!-- <p id="text3dAttr"></p> -->
      </div>
      <mat-tab-group>

        <mat-tab *ngIf="layerFeature">
          <ng-template mat-tab-label> Слои </ng-template>
          <pre id="features0"></pre>

        </mat-tab>
        <mat-tab *ngIf="layer3D">
          <ng-template mat-tab-label> 3Dcлои </ng-template>
          <pre id="features1"></pre>
        </mat-tab>
      </mat-tab-group>
    </div>
    
  </div>

</mat-drawer-container>
