import {Language} from './language';
import {Browser} from 'leaflet';

const siteSettings = {
  identity: null,
  language: Language.KAZ,
  search: true,
  isClearAllLyrs: false,
  filter: [],
  isCoordinate: false,
  print: false,
};

export default siteSettings;
