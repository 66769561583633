import {Component, OnInit} from '@angular/core';
import siteSettings from '../../../shared/config/site-settings';
import {Language} from '../../../shared/config/language';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-info-play-dialog',
  templateUrl: './info-play-dialog.component.html',
  styleUrls: ['./info-play-dialog.component.scss']
})
export class InfoPlayDialogComponent implements OnInit {
  panelOpenState = false;
  siteSettings = siteSettings;
  language = Language;

  constructor(public dialogRef: MatDialogRef<InfoPlayDialogComponent>) {
  }

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close();
    (document.querySelector('.infoPlay') as HTMLElement).style.pointerEvents = 'auto';
  }

}
