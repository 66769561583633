import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from './shared/config/language';
import siteSettings from './shared/config/site-settings';


@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styles: ['']
})
export class AppComponent implements OnInit {

  constructor(private translate: TranslateService) {
    translate.setDefaultLang('kk');
  }

  ngOnInit(): void {
    const language: number = JSON.parse(localStorage.getItem('eJambylLanguage'));
    let lang = 'kk';

    if (language) {
      if (language === Language.KAZ) {
        lang = 'kk';
      }
      if (language === Language.ENG) {
        lang = 'en';
      }
    }

    this.translate.use(lang);

    siteSettings.language = language ? language : Language.KAZ;
  }
}
