<div class="containerDialog">
    <div class="headerOpenDialog">
      <p class="titleDialog" *ngIf="siteSettings.language===language.KAZ">Бейне нұсқау</p>
      <p class="titleDialog" *ngIf="siteSettings.language===language.RUS">Видео инструкция</p>
      <p class="titleDialog" *ngIf="siteSettings.language===language.ENG">Video instruction</p>
      
      <div mat-flat-button class="btnDiv" (click)="onClose()">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="containerBody">
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
          class="matAccordInfoDialog">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <img src="/assets/images/play_24px.svg" width="30px" height="30px">
              <p class="titleName" *ngIf="siteSettings.language===language.KAZ">АШЫҚ КАРТАСЫНА ШОЛУ</p>
              <p class="titleName" *ngIf="siteSettings.language===language.RUS">ОБЗОР ОТКРЫТОЙ КАРТЫ</p>
              <p class="titleName" *ngIf="siteSettings.language===language.ENG">OVERVIEW OPEN MAP</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="video-container">
            <iframe *ngIf="siteSettings.language===language.RUS" src="https://www.youtube.com/embed/BLsoernwrhg?rel=0" allow="autoplay; encrypted-media"
              allowfullscreen="allowfullscreen" frameborder="0">
            </iframe>
            <iframe  *ngIf="siteSettings.language===language.KAZ" src="https://www.youtube.com/embed/GhSjKsuFkRU?rel=0" allow="autoplay; encrypted-media"
              allowfullscreen="allowfullscreen" frameborder="0">
            </iframe>
          </div>
        </mat-expansion-panel>
  
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
          class="matAccordInfoDialog">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M25.1719 9.20312L23.0391 11.3359L18.6641 6.96094L20.7969 4.82812C21.0156 4.60938 21.2891 4.5 21.6172 4.5C21.9453 4.5 22.2188 4.60938 22.4375 4.82812L25.1719 7.5625C25.3906 7.78125 25.5 8.05469 25.5 8.38281C25.5 8.71094 25.3906 8.98438 25.1719 9.20312ZM4.5 21.125L17.4062 8.21875L21.7812 12.5938L8.875 25.5H4.5V21.125Z"
                  fill="#00A0E3" />
              </svg>
              <p class="titleName" *ngIf="siteSettings.language===language.KAZ">ЖЕР САУДА-САТТЫҒЫ</p>
              <p class="titleName" *ngIf="siteSettings.language===language.RUS">ЗЕМЕЛЬНЫЕ ТОРГИ</p>
              <p class="titleName" *ngIf="siteSettings.language===language.ENG">LAND TENDERS</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="video-container">
            <iframe *ngIf="siteSettings.language===language.RUS" src="https://www.youtube.com/embed/opqXMikGS8c?rel=0" allow="autoplay; encrypted-media"
              allowfullscreen="allowfullscreen" frameborder="0">
            </iframe>
            <iframe  *ngIf="siteSettings.language===language.KAZ" src="https://www.youtube.com/embed/dTccYySJ78w?rel=0" allow="autoplay; encrypted-media"
              allowfullscreen="allowfullscreen" frameborder="0">
            </iframe>
          </div>
        </mat-expansion-panel>
  
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
          class="matAccordInfoDialog">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15 19.6484C14.6719 19.3932 13.1771 18.2448 10.5156 16.2031C7.89062 14.125 5.88542 12.5573 4.5 11.5L15 3.35156L25.5 11.5C24.1146 12.5573 22.1094 14.1068 19.4844 16.1484C16.8594 18.1901 15.3646 19.3568 15 19.6484ZM15 22.6562L23.5859 15.9297L25.5 17.4062L15 25.5547L4.5 17.4062L6.41406 15.9297L15 22.6562Z"
                  fill="#00A0E3" />
              </svg>
              <p class="titleName" *ngIf="siteSettings.language===language.KAZ">ТЕМАТИКАЛЫҚ КАРТАЛАР</p>
              <p class="titleName" *ngIf="siteSettings.language===language.RUS">ТЕМАТИЧЕСКИЕ КАРТЫ</p>
              <p class="titleName" *ngIf="siteSettings.language===language.ENG">THEMATIC MAPS</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="video-container">
            <iframe *ngIf="siteSettings.language===language.RUS" src="https://www.youtube.com/embed/DKIdvTxGF_8?rel=0" allow="autoplay; encrypted-media"
              allowfullscreen="allowfullscreen" frameborder="0">
            </iframe>
            <iframe  *ngIf="siteSettings.language===language.KAZ" src="https://www.youtube.com/embed/7fsuuw4Lw-0?rel=0" allow="autoplay; encrypted-media"
              allowfullscreen="allowfullscreen" frameborder="0">
            </iframe>
          </div>
        </mat-expansion-panel>
  
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
          class="matAccordInfoDialog">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 22.6177H7.88235V26.5H4V22.6177Z" fill="#00A0E3" />
                <path d="M22.1178 22.6177H26.0001V26.5H22.1178V22.6177Z" fill="#00A0E3" />
                <path d="M5.29397 21.3235H6.58809V18.7353H11.7646V17.4412H5.29397V21.3235Z" fill="#00A0E3" />
                <path d="M23.4117 21.3235H24.7059V17.4412H18.2353V18.7353H23.4117V21.3235Z" fill="#00A0E3" />
                <path
                  d="M15.647 13.5588H19.5294C21.3133 13.5588 22.7647 12.1075 22.7647 10.3235C22.7647 8.6709 21.5191 7.30371 19.9175 7.1114C19.416 5.93423 18.253 5.14706 16.9411 5.14706C16.4085 5.14706 15.8864 5.27927 15.4214 5.52671C14.8154 4.88185 13.9578 4.5 13.0588 4.5C11.4773 4.5 10.1571 5.64076 9.87794 7.14267C8.37603 7.42186 7.23527 8.74203 7.23527 10.3235C7.23527 12.1075 8.68662 13.5588 10.4706 13.5588H14.3529V16.1471H13.0588V20.0294H14.3529V22.6176H13.0588V26.5H16.9411V22.6176H15.647V20.0294H16.9411V16.1471H15.647V13.5588Z"
                  fill="#00A0E3" />
              </svg>
              <p class="titleName" *ngIf="siteSettings.language===language.KAZ">ҚОСЫМША</p>
              <p class="titleName" *ngIf="siteSettings.language===language.RUS">ДОПОЛНИТЕЛЬНЫЕ</p>
              <p class="titleName" *ngIf="siteSettings.language===language.ENG">ADDITIONAL</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="video-container">
            <iframe *ngIf="siteSettings.language===language.RUS" src="https://www.youtube.com/embed/lqwGqBp4ANY?rel=0" allow="autoplay; encrypted-media"
              allowfullscreen="allowfullscreen" frameborder="0">
            </iframe>
            <iframe  *ngIf="siteSettings.language===language.KAZ" src="https://www.youtube.com/embed/vT-ZmhTk-6I?rel=0" allow="autoplay; encrypted-media"
              allowfullscreen="allowfullscreen" frameborder="0">
            </iframe>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  
  
  </div>
  