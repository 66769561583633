import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {MapControlsModule} from './map-controls/map-controls.module';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {HeaderComponent} from './l-sidenav/header/header.component';
import {BottomComponent} from './l-sidenav/bottom/bottom.component';
import {AppComponent} from './app.component';
import {CoreComponent} from './core/core.component';
import {InfoDialogComponent} from './l-sidenav/bottom/info-dialog/info-dialog.component';
import {ContactDialogComponent} from './l-sidenav/bottom/contact-dialog/contact-dialog.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatInputModule} from '@angular/material/input';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import { InfoPlayDialogComponent } from './l-sidenav/bottom/info-play-dialog/info-play-dialog.component';
import { PdpDialogComponent } from './l-sidenav/bottom/pdp-dialog/pdp-dialog.component';

@NgModule({
  declarations: [AppComponent, HeaderComponent, BottomComponent, CoreComponent,
    InfoDialogComponent, ContactDialogComponent, InfoPlayDialogComponent, PdpDialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MapControlsModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'}},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
