import {Component, HostListener, OnInit} from '@angular/core';
import {MapService} from 'src/app/shared/services/map.service';
import * as L from 'leaflet';
import siteSettings from '../../shared/config/site-settings';
import {LayersManagerService} from '../../shared/services/layers-manager.service';
import html2canvas from 'html2canvas';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ILayersLegend } from 'src/app/shared/models';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-zoom-in-out',
  templateUrl: './zoom-in-out.component.html',
  styleUrls: ['./zoom-in-out.component.scss'],
  providers: [DatePipe]
})
export class ZoomInOutComponent implements OnInit {
  toolsShow = false;
  copyBody;
  private destroy$ = new Subject();
  legendNames: ILayersLegend[] = new Array();
  browserPrint;
  map: L.Map;
  maxScale = 559082264;
  maxZoom = 0;
  currentZoom;
  lastZoom;

  constructor(private mapSvc: MapService,
              private layersManagerService: LayersManagerService,
              private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.legPrint();
  }

  changeZoom(state: boolean): void {
    const map = this.mapSvc.getMap();
    let zoomLevel = map.getZoom();
    map.setZoom(state ? ++zoomLevel : --zoomLevel);
  }

  extentZoom(): void {
    const map = this.mapSvc.getMap();
    // map.flyTo([42.3417, 69.5901], 12, {
    //   animate: true
    // });
  }

  locateUser(): void {
    const map = this.mapSvc.getMap();
    if (navigator.geolocation) {
      this.mapSvc.clearEditableLayers();
      navigator.geolocation.getCurrentPosition((position) => {
        L.circle([position.coords.latitude, position.coords.longitude], {
          radius: 29,
          color: 'blue',
        }).addTo(this.mapSvc.editableLayers);
        // map.setView([position.coords.latitude, position.coords.longitude], 17);
      });
    } else {
      alert('Ваш браузер не поддерживает HTML5 Geolocation');
    }
  }

  // clearLayers(): void {
  //   // this.layersManagerService.addLayer('crime', '1=1');
  //   this.layersManagerService.clearSearchMarkerByAddressRegister();
  //   siteSettings.isClearAllLyrs = true;
  //   // this.layersManagerService.removeAllVisibleLayers();
  // }

  tools(): void {
    this.toolsShow = !this.toolsShow;
  }

  print(): void {
    this.legendNames = [];
    this.printMaps();
  }

  legPrint(): void {
    this.layersManagerService.visibleLayers$
    .pipe(takeUntil(this.destroy$))
    .subscribe((data: any) => {
      this.legendNames.length = 0;
      data.forEach((l) => {
        this.legendNames.push({
          layerName: l.options.layers,
          nameRu: l.options.nameRu,
          src: this.getLegendSrc(l),
        });
      });
    });
  }

  getLegendSrc(layer: any): string {
    return (
       // tslint:disable-next-line:max-line-length
      `https://dm.geo-shym.kz/geoserver/gis/wms?service=WMS&REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=%20%20%20%20%20%20${layer.options.layers}`
    );
  }

  printMaps(): void {
    const WindowPrt = window;
    // (document.querySelector('.main-container') as HTMLElement).style.display = 'none';
    // WindowPrt.document.close();toggle-left-sidenav
    // WindowPrt.focus();
    (document.querySelector('.left-sidenav') as HTMLElement).style.display = 'none';
    (document.querySelector('.toggle-left-sidenav') as HTMLElement).style.display = 'none';
    (document.querySelector('.mapboxgl-ctrl-top-right') as HTMLElement).style.display = 'none';
    (document.querySelector('.control1') as HTMLElement).style.display = 'none';
    (document.querySelector('.zoom-controls') as HTMLElement).style.display = 'none';
    setTimeout(() => {
      WindowPrt.print();
      // WindowPrt.close();
      WindowPrt.stop();
      siteSettings.print = false;
      (document.querySelector('.left-sidenav') as HTMLElement).style.display = 'block';
      (document.querySelector('.toggle-left-sidenav') as HTMLElement).style.display = 'block';
      (document.querySelector('.mapboxgl-ctrl-top-right') as HTMLElement).style.display = 'block';
      (document.querySelector('.control1') as HTMLElement).style.display = 'block';
      (document.querySelector('.zoom-controls') as HTMLElement).style.display = 'block';
    }, 2000);
    // window.print();

    // //workaround for Chrome bug - https://code.google.com/p/chromium/issues/detail?id=141633
    // if (window.stop) {
    //     location.reload(); //triggering unload (e.g. reloading the page) makes the print dialog appear
    //     window.stop(); //immediately stop reloading
    // }
    return;
    // return;
    const mapDiv = document.querySelector('#mapid') as HTMLDivElement;
    const offsetWidth = mapDiv.clientWidth;
    const offsetHeight = mapDiv.clientHeight;



    const map = this.mapSvc.getMap();
    siteSettings.print = true;
    this.currentZoom = focus();
    this.lastZoom = focus();

    if (this.currentZoom !== this.lastZoom) {
      // this.spinner.show();
      this.lastZoom = this.currentZoom;
      this.fillInfo(offsetWidth, offsetHeight);
      map.setZoom(this.currentZoom);
    } else {
      // write info
      this.fillInfo(offsetWidth, offsetHeight);
      const element = document.getElementsByClassName('browser-print-mode') as unknown as HTMLElement;
      console.log('element', element);
      element[1].click();
      siteSettings.print = false;
    }

  }

  fillInfo(offsetWidth, offsetHeight): void {
    const scaleMM = 'Размер карты: ';
    const date = 'Дата: ';
    const w = Math.floor(offsetWidth * 0.264583);
    const h = Math.floor(offsetHeight * 0.264583);
    const currDate = new Date();
    const currDateStr = this.datePipe.transform(currDate, 'dd.MM.yyyy HH:mm');
    const sizeSpan = document.querySelector('span.map-info-size') as HTMLDivElement;
    sizeSpan.innerText = `${scaleMM}: ${w}mm x ${h}mm`;
    const dateSpan = document.querySelector('span.map-info-date') as HTMLDivElement;
    dateSpan.innerText = `${date}: ${currDateStr}`;
  }

  getPrintDescription(offsetWidth, offsetHeight, offsetHeights): string {
    this.layersManagerService.visibleLayers$
    .pipe(takeUntil(this.destroy$))
    .subscribe((data: any) => {
      data.forEach((l) => {
        this.legendNames.push({
          layerName: l.options.layers,
          nameRu: l.options.nameRu,
          src: this.getLegendSrc(l),
        });
      });
    });

    const title = 'Шымкент PГИС';
    const scaleMM = 'Размер карты: ';
    const date = 'Дата: ';
    const url = 'Ссылка на сайт: ';
    const legend = 'Легенда';
    const w = Math.floor(offsetWidth * 0.264583);
    const h = Math.floor(offsetHeight * 0.264583);
    const f = Math.floor(offsetHeights * 0.264583);
    const currDate = new Date();
    const currDateStr = this.datePipe.transform(currDate, 'dd.MM.yyyy HH:mm');
    offsetHeight += 200;
    offsetHeights += 370;

    const html = `<div style="display:block;top:${offsetHeight}; position:absolute;top:bottom: 0px;left:0">`
    + `<p style="font-size: 14px;"><b>${title}</b></p>`
    + `<p>${scaleMM}: ${w}mm x ${h}mm</p>`
    + `<p>${date}: ${currDateStr}</p>`
    + `<p>${url}: <a href="https://openmap.geo-shym.kz">https://openmap.geo-shym.kz</a></p>`
    + `<p style="color: green;">${legend}</p>`
    + `</div>`;
    let textContent = ``;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.legendNames.length; i++) {
      textContent += `<div style="display:block; top:${offsetHeights}; position: relative; top:bottom: 0px; left: 0; margin-bottom: 20px;">`
      + `<p style="color: #000;">${this.legendNames[i].nameRu}</p>`
      + `<img src="${this.legendNames[i].src}">`
      + `</div>`;
    }
    return html + textContent;
  }

  @HostListener('window:afterprint')
  // tslint:disable-next-line:typedef
  onafterprint() {
    const body = document.querySelector('body') as HTMLBodyElement;
    // body.innerHTML = this.copyBody;
    document.location.reload();
  }

  screen(): void {
    // (document.querySelector('.leaflet-ruler') as HTMLElement).style.display = 'none';
    // (document.querySelector('.leaflet-control-measure') as HTMLElement).style.display = 'none';
    const mapContainer = document.getElementById('layer');
    html2canvas(mapContainer, {
      useCORS: false,
      allowTaint: true,
    }).then((canvas) => {
      const data = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      
      const element = document.createElement('a');console.log('element', element)
      element.setAttribute('href', data);
      element.setAttribute('download', 'map.png');
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
    // (document.querySelector('.leaflet-ruler') as HTMLElement).style.display = 'block';
    // (document.querySelector('.leaflet-control-measure') as HTMLElement).style.display = 'block';
  }

  measureDistance(): void {
  }

  measureArea(): void {
  }

  getCoordinates(): void {
    siteSettings.isCoordinate = true;
  }

  // generateMapScreen(): void {
  //   console.log('settings');
  //   const mapContainer = document.getElementById('mapid');
  //   html2canvas(mapContainer, {
  //     useCORS: true
  //   }).then((canvas) => {
  //     const jpegUrl = canvas.toDataURL('image/jpeg');
  //     const arrImg = jpegUrl.split(',');
  //     // this.base64textString = arrImg[arrImg.length - 1];
  //     // this.mapControlsSvc.hideControls(false);
  //   }).catch(e => console.log(e));
  // }
}
