import {Component, OnInit} from '@angular/core';
import siteSettings from '../../../shared/config/site-settings';
import {Language} from '../../../shared/config/language';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  siteSettings = siteSettings;
  language = Language;

  constructor() {
  }

  ngOnInit(): void {
  }

}
